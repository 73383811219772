import React from 'react'

import Img from '@/components/common/Img'

interface Props {
  className?: string
  text?: string
  style?: React.CSSProperties
}

const Loading: React.FC<Props> = ({
  className,
  text = '설탕노움이 열심히 페이지를 만들고 있습니다',
  style,
}) => {
  return (
    <div
      className={`flex min-h-screen min-w-full flex-col items-center justify-center gap-4 p-4 ${className}`}
      style={style}>
      <Img
        className="h-16 w-16 object-contain"
        src="https://blog.kakaocdn.net/dn/bhfQqS/btrjbyMezrk/4KnsrBkEQKv7P7PT36jTb0/img.gif"
      />
      {text && <div className="text-base">{text}</div>}
    </div>
  )
}

export default Loading
