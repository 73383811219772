import { QueryConstraint, limit, startAfter } from 'firebase/firestore'

import { ArticleCategoryWithAll } from '@/components/ckk/WriteBoard'
import { Article, BaseArticle, PostType } from '@/types/ckk'
import {
  createCollectionDoc,
  deleteCollectionDoc,
  getCollection,
  readCollectionDocById,
  updateCollectionDoc,
} from '@/utils/firebase'
import { readComments } from '@/utils/firebase/comment'
import { readProfileByUid } from '@/utils/firebase/profile'
import { sendTelegramMessage } from '@/utils/telegram'

const articleCollectionRef = getCollection('articles')

export const createArticle = createCollectionDoc<BaseArticle>({
  collectionRef: articleCollectionRef,
})

export const readArticles = async ({
  uid,
  term,
  category,
  withNotification = false,
  featured,
}: {
  uid?: string
  term?: string
  category?: ArticleCategoryWithAll
  withNotification?: boolean
  featured?: boolean
}): Promise<Article[]> => {
  const { query, where, getDocs, orderBy } = await import('firebase/firestore')
  const articlesWithKey: BaseArticle[] = []

  const queryConstraints: QueryConstraint[] = [orderBy('createdAt', 'desc')]

  if (uid) {
    queryConstraints.push(where('author.uid', '==', uid))
  }

  if (term) {
    queryConstraints.push(where('title.ko', '==', term))
  }

  if (category && category !== 'ALL') {
    queryConstraints.push(where('category', '==', category))
  }

  if (featured) {
    queryConstraints.push(where('featured', '==', featured))
  }

  const q = query(articleCollectionRef, ...queryConstraints)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((_doc) => {
    const articleWithKey = { id: _doc.id, ..._doc.data() } as BaseArticle
    articlesWithKey.push(articleWithKey)
  })

  if (withNotification) {
    sendTelegramMessage({
      topic: '복수 글 조회 / readArticles',
      message: `
조회 Query : ${JSON.stringify({ term }, null, 4)}
조회 결과 : ${articlesWithKey.join(', ')}`,
    })
  }

  const articles = await Promise.all(
    articlesWithKey.map(async (article) => {
      const profile = await readProfileByUid({ uid: article.author.uid })
      const comments = await readComments({
        postType: PostType.ARTICLE,
        postId: article.id,
      })
      return {
        ...article,
        author: profile,
        comments,
      }
    }),
  )

  return articles
}

export const ARTICLE_PAGE_SIZE = 10

export const readPagedArticles = async ({
  uid,
  term,
  category,
  createdAt,
  pageSize = ARTICLE_PAGE_SIZE,
  withNotification = false,
}: {
  uid?: string
  term?: string
  category?: ArticleCategoryWithAll
  createdAt?: string
  withNotification?: boolean
  pageSize?: number
}): Promise<Article[]> => {
  const { query, where, getDocs, orderBy } = await import('firebase/firestore')

  const articlesWithKey: BaseArticle[] = []
  const queryConstraints: QueryConstraint[] = []

  if (uid) {
    queryConstraints.push(where('author.uid', '==', uid))
  }
  if (term) {
    /** term이 title.ko에 포함되는 경우 */
    queryConstraints.push(where('title.ko', '>=', term))
    queryConstraints.push(where('title.ko', '<=', term + '\uf8ff'))
    queryConstraints.push(orderBy('title.ko', 'desc'))
  }
  if (category && category !== 'ALL') {
    queryConstraints.push(where('category', '==', category))
  }
  queryConstraints.push(orderBy('createdAt', 'desc'))
  if (createdAt) {
    queryConstraints.push(startAfter(createdAt))
  }
  queryConstraints.push(limit(pageSize))

  const q = query(articleCollectionRef, ...queryConstraints)

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((_doc) => {
    const articleWithKey = { id: _doc.id, ..._doc.data() } as BaseArticle
    articlesWithKey.push(articleWithKey)
  })

  if (withNotification) {
    sendTelegramMessage({
      topic: '복수 글 조회 / readArticles',
      message: `
조회 Query : ${JSON.stringify({ term }, null, 4)}
조회 결과 : ${articlesWithKey.join(', ')}`,
    })
  }

  const articles = await Promise.all(
    articlesWithKey.map(async (article) => {
      const profile = await readProfileByUid({ uid: article.author.uid })
      const comments = await readComments({
        postType: PostType.ARTICLE,
        postId: article.id,
      })
      return {
        ...article,
        author: profile,
        comments,
      }
    }),
  )

  return articles
}

export const updateArticle = updateCollectionDoc<BaseArticle>({
  collectionRef: articleCollectionRef,
})

export const readArticleWithKeyById = readCollectionDocById<BaseArticle>({
  collectionRef: articleCollectionRef,
})

export const deleteArticle = deleteCollectionDoc({
  collectionRef: articleCollectionRef,
})
