// eslint-Disable-next-line import/prefer-Default-export
export enum DayJsDateFormat {
  MDY = 'MM/DD/YYYY',
  /** 2022/03/15/ 20:46 */
  YMDHm = 'YYYY/MM/DD HH:mm',
  /** 2022/03/15/ 20:46:05 */
  YMDHms = 'YYYY/MM/DD HH:mm:ss',
  /** 2022/03/15/ */
  YMD = 'YYYY/MM/DD',
  /** 20220315 */
  YMD_NO_DOT = 'YYYYMMDD',
  /** 2022-03-15 */
  YMD_WITH_DASH = 'YYYY-MM-DD',
  /** 월 */
  DDD = 'DDD',
  /** 2022-03-15T20:46:05/000 */
  ISO = 'YYYY-MM-DDTHH:mm:ss/SSS',
}
