/** enum 타입을 받아서 value들을 배열로 반환하는 함수 */
export function getValuesFromEnum(e: unknown): (number | string)[] {
  const filteredEnum = Object.values(e).filter(
    (value) => typeof value === 'number',
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredEnum?.length
    ? (Object.values(e).slice(
        Object.values(e).length / 2,
        Object.values(e).length,
      ) as (number | string)[])
    : (Object.values(e) as (number | string)[])
}

export function getKeysFromEnum(e: unknown): string[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.values(e).filter(
    (value) => typeof value === 'string',
  ) as string[]
}

export function getOptionsFromEnum(
  e: unknown,
): { label: string; value: string }[] {
  return Object.keys(e).map((key) => ({ label: e[key], value: e[key] }))
}
