import dynamic from 'next/dynamic'

import { useMemo } from 'react'

import { AxiosError } from 'axios'
import ReactQuill, { ReactQuillProps } from 'react-quill'

import Loading from '@/components/Loading'

import 'react-quill/dist/quill.snow.css'

interface ForwardedQuillComponent extends Omit<ReactQuillProps, 'value'> {
  forwardedRef: React.MutableRefObject<ReactQuill>
  content: string
  setContent: (prevContent: string) => void
  setImages?: React.Dispatch<React.SetStateAction<string[]>>
  setIsImageUploading: React.Dispatch<React.SetStateAction<boolean>>
}

interface CustomImageBlotProps {
  alt: string
  src: string
  dataSrc: string
  width?: string
  height?: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
async function getCustomImageBlot(QuillComponent: typeof ReactQuill) {
  const ImageBlot = await QuillComponent.Quill.import('formats/image')
  class CustomImageBlot extends ImageBlot {
    static create(value: CustomImageBlotProps) {
      const img: HTMLElement = super.create(value)
      const link = document.createElement('a')
      link.setAttribute('href', value.src || '#')
      link.setAttribute('target', '_blank')

      img.setAttribute('src', value.src)
      img.setAttribute('alt', value.alt || '')
      img.setAttribute('width', value?.width ?? '100%')
      if (value.height) {
        img.setAttribute('height', value.height)
      }
      img.style.cursor = 'pointer'
      img.style.maxWidth = '700px'
      img.dataset.src = value.src
      img.setAttribute('referrerpolicy', 'no-referrer')

      link.appendChild(img)
      return link
    }

    static value(
      domNode: /** img 태그를 자식 노드로 가진 a tag 타입 */
      HTMLAnchorElement,
    ): CustomImageBlotProps {
      const imageNode = (domNode?.children[0] ?? domNode) as HTMLImageElement
      return {
        alt: imageNode?.getAttribute('alt') || '',
        src: imageNode?.getAttribute('src') || '',
        width: imageNode?.getAttribute('width') || '100%',
        height: imageNode?.getAttribute('height') || '',
        dataSrc: imageNode?.dataset?.src || '',
      }
    }
  }
  return CustomImageBlot
}

const QuillNoSSRWrapper = dynamic(
  async () => {
    const { default: QuillComponent } = await import('react-quill')
    // eslint-disable-next-line import/no-extraneous-dependencies
    const { default: ImageResize } = await import(
      'quill-image-resize-module-react'
    )

    const fontSizeStyle = await QuillComponent.Quill.import(
      'attributors/style/size',
    )
    fontSizeStyle.whitelist = ['11px', '13px', '19px', '28px', '38px']

    await QuillComponent.Quill.register(fontSizeStyle, true)

    const AlignClass = QuillComponent.Quill.import('attributors/class/align')
    QuillComponent.Quill.register(AlignClass, true)

    const ImageBlot = await getCustomImageBlot(QuillComponent)
    QuillComponent.Quill.register(ImageBlot)
    QuillComponent.Quill.register('modules/imageResize', ImageResize)

    const QuillWithRef = ({
      forwardedRef: quillRef,
      content,
      setContent,
      setImages,
      setIsImageUploading,
      ...props
    }: ForwardedQuillComponent) => {
      const modules = useMemo(() => {
        // 이미지를 업로드 하기 위한 함수
        const imageHandler = () => {
          // 파일을 업로드 하기 위한 input 태그 생성
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')
          input.click()

          // 파일이 input 태그에 담기면 실행 될 함수
          input.onchange = async () => {
            const { files } = input
            if (files !== null && files.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              const file = files[0]
              const formData = new FormData()
              formData.append('file', file)

              try {
                const reader = new FileReader()

                reader.onload = async function async() {
                  const quill = quillRef.current?.getEditor()
                  const { index } = quill.getSelection()

                  setIsImageUploading(true)
                  quill.enable(false)
                  try {
                    const { default: axios } = await import('axios')
                    const {
                      data: { uploadURL },
                    } = await axios.post<
                      unknown,
                      { data: { uploadURL: string } }
                    >('/api/cloudflare/direct_upload')

                    const {
                      data: {
                        result: { id },
                      },
                    } = await axios.post<
                      unknown,
                      {
                        data: {
                          result: {
                            id: string
                            filename: string
                            uploaded: string
                            requireSignedURLs: boolean
                            variants: string[]
                          }
                          success: boolean
                          errors: unknown[]
                          messages: unknown[]
                        }
                      }
                    >(uploadURL, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    })

                    if (index !== null && index !== undefined) {
                      const imageUrl = `https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/${id}/public`
                      // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
                      quill.deleteText(index, 1)

                      quill.insertEmbed(index, 'image', {
                        src: imageUrl,
                        loading: 'lazy',
                        alt: 'image',
                      })

                      quill?.setSelection(index, 1)
                      setImages?.((prevImages) => [...prevImages, imageUrl])
                      quill.enable(true)
                      setIsImageUploading(false)
                    }
                  } catch (e) {
                    quill.deleteText(index, 1)
                  }
                }
                reader.readAsDataURL(file)

                return { success: true }
              } catch (error) {
                const err = error as AxiosError
                return { ...err.response, success: false }
              }
            }
          }
        }
        return {
          toolbar: {
            container: '#toolbar',
            handlers: {
              image: imageHandler,
            },
          },
          imageResize: {
            parchment: QuillComponent.Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
          },
        }
      }, [quillRef, setImages, setIsImageUploading])

      const formats = [
        'bold',
        'italic',
        'header',
        'underline',
        'strike',
        'blockquote',
        'size',
        'color',
        'background',
        'list',
        'align',
        'link',
        'image',
        'video',
        'keepHTML',
        'float',
        'height',
        'width',
        'style',
      ]

      return (
        <QuillComponent
          ref={quillRef}
          {...props}
          defaultValue={content}
          formats={formats}
          modules={modules}
          value={content}
          onChange={(value, delta, source, editor) => {
            if (setImages) {
              const imageOps = editor.getContents().ops.filter((op) => {
                return !!op?.insert?.image
              })
              const nextImages = imageOps.map(({ insert }) => {
                const { image } = insert
                return image.src
              })

              setImages(nextImages)
            }
            setContent(value)
          }}
        />
      )
    }
    return QuillWithRef
  },
  {
    loading: () => <Loading style={{ minHeight: '200px !important' }} />,
    ssr: false,
  },
)

export default QuillNoSSRWrapper
